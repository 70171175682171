<template>
  <div style="height: 100%; background-color: #fff; padding-top: 12px">
    <!-- <div class="formContentBox"> -->
    <head-layout
      head-title=""
      :head-btn-options="headBtnOptions"
      @head-save="handleSave"
    ></head-layout>
    <div style="padding-top: 10px">
      <avue-form :option="formOptions" ref="addForm" v-model="addForm">
        <template slot="areaId">
          <el-cascader
            placeholder="请选择注册位置"
            :disabled="opType == 'view'"
            ref="prjAreaCascader"
            filterable
            style="width: 100%"
            v-model="addForm.areaId"
            :options="areaDic"
            @change="handleChangeArea"
          ></el-cascader>
        </template>
      </avue-form>
    </div>

    <!-- </div> -->
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";

import { getDetail, add } from "@/api/safe/thirdcompany";
import { getLazyTree, getAreaData1 } from "@/api/base/region";
export default {
  components: {
    HeadLayout,
  },
  data() {
    return {
      addForm: {},
      areaDic: [],
      currentId: "",
      opType: "",
    };
  },
  computed: {
    formOptions() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "公司名称",
            prop: "companyName",
            span: 8,
            disabled: true,
          },
          {
            label: "统一社会信用代码",
            prop: "companyCode",
            span: 8,
            disabled: true,
          },
          {
            label: "法定代表人",
            prop: "legalRep",
            span: 8,
            disabled: this.opType == "view",
            rules: [
              {
                required: false,
                message: "请输入法定代表人",
                trigger: "blur",
              },
              {
                max: 20,
                message: "法定代表人不能超过20个字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "注册位置",
            prop: "areaId",
            formslot: true,
            type: "cascader",
            dicData: this.areaDic,
            span: 12,
            disabled: this.opType == "view",
            rules: [
              {
                required: false,
                message: "请选择注册位置",
                trigger: "change",
              },
            ],
          },
          {
            label: "详细位置",
            prop: "regLocation",
            span: 12,
            disabled: this.opType == "view",
            rules: [
              {
                required: false,
                message: "请输入详细位置",
                trigger: "blur",
              },
              {
                max: 255,
                message: "详细位置不能超过255个字符",
                trigger: "blur",
              },
            ],
          },
          {
            label: "成立日期",
            prop: "curDate",
            span: 8,
            disabled: this.opType == "view",
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            "picker-options": {
              disabledDate: (time) => {
                return time.getTime() > Date.now() - 3600 * 1000 * 24;
              },
            },
            rules: [
              {
                required: false,
                message: "请输入成立日期",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.opType)) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
      }
      return result;
    },
  },
  methods: {
    init(id, opType) {
      this.currentId = id;
      this.opType = opType;
      this.getAreaData();
      this.getDetails(id);
    },
    getDetails(id) {
      getDetail(id).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.addForm = data;
          this.addForm.areaId = this.addForm.areaId
            ? this.addForm.areaId.split(",")
            : [];
        }
      });
    },
    getAgeByBirthday(data) {
      return calculateAge(data);
    },
    // 区域位置获取
    handleChangeArea(value) {
      const regionList =
        this.$refs["prjAreaCascader"].getCheckedNodes()[0].pathLabels;
      if (regionList && regionList.length != 0) {
        this.addForm.areaName = regionList.join(",");
      }
      // const selectedLabel = this.getNodeLabel(this.areaDic, value);
      // console.log('selectedLabel--区域位置---',selectedLabel)
    },
    //   项目位置
    getAreaData(parentCode) {
      getAreaData1(parentCode, this.areaDic)
    },
    // //   项目位置
    // getAreaData(parentCode) {
    //   getLazyTree(parentCode).then((res) => {
    //     if (res.data.data && res.data.data.length != 0) {
    //       this.areaDic = this.generateOptions(res.data.data);
    //     }
    //   });
    // },
    generateOptions(params) {
      var result = [];
      for (const param of params) {
        if (param.parentId == "10") {
          // 判断是否为顶层节点
          var parent = {
            id: param.id,
            label: param.title,
            value: param.id,
            title: param.title,
          };
          parent.children = this.getchilds(param.id, params); // 获取子节点
          result.push(parent);
        }
      }
      return result;
    },
    getchilds(id, array) {
      const childs = [];
      for (const arr of array) {
        // 循环获取子节点
        if (arr.parentId === id) {
          childs.push({
            id: arr.id,
            label: arr.title,
            value: arr.id,
            title: arr.title,
          });
        }
      }
      for (const child of childs) {
        // 获取子节点的子节点
        const childscopy = this.getchilds(child.id, array); // 递归获取子节点
        if (childscopy.length > 0) {
          child.children = childscopy;
        }
      }
      return childs;
    },
    handleSave() {
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.addForm))
          params.areaId = params.areaId.length ? params.areaId.join(",") : ""
          add(params).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
              this.getDetails(this.currentId);
            } else {
              this.$message.success(msg);
            }
            done();
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.formContentBox {
  padding: 12px 0;
  height: calc(100% - 76px);
}
</style>
