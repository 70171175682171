var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        height: "100%",
        "background-color": "#fff",
        "padding-top": "12px",
      },
    },
    [
      _c("head-layout", {
        attrs: { "head-title": "", "head-btn-options": _vm.headBtnOptions },
        on: { "head-save": _vm.handleSave },
      }),
      _c(
        "div",
        { staticStyle: { "padding-top": "10px" } },
        [
          _c(
            "avue-form",
            {
              ref: "addForm",
              attrs: { option: _vm.formOptions },
              model: {
                value: _vm.addForm,
                callback: function ($$v) {
                  _vm.addForm = $$v
                },
                expression: "addForm",
              },
            },
            [
              _c(
                "template",
                { slot: "areaId" },
                [
                  _c("el-cascader", {
                    ref: "prjAreaCascader",
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请选择注册位置",
                      disabled: _vm.opType == "view",
                      filterable: "",
                      options: _vm.areaDic,
                    },
                    on: { change: _vm.handleChangeArea },
                    model: {
                      value: _vm.addForm.areaId,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "areaId", $$v)
                      },
                      expression: "addForm.areaId",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }