var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personnelHolography-detail" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "相关方组织全息",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-cancel": _vm.headCancel },
      }),
      _c(
        "div",
        { staticStyle: { height: "calc(100% - 52px)" } },
        [
          _c(
            "el-tabs",
            {
              attrs: { "tab-position": "left", type: "border-card" },
              on: { "tab-click": _vm.handleTabClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "组织基本信息", name: "orgBaseInfo" } },
                [_c("orgBaseInfo", { ref: "orgBaseInfo" })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "项目记录", name: "projectRecord" } },
                [_c("projectRecord", { ref: "projectRecord" })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "企业证照", name: "enterpriseLicence" } },
                [_c("enterpriseLicence", { ref: "enterpriseLicence" })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "黑名单", name: "blackList" } },
                [_c("blackList", { ref: "blackList" })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }